import type { App } from "vue";

import * as Sentry from "@sentry/vue";

import { getEnv } from "@solvari/utils";

import type { SentryRootProject } from "@/lib/integrations/sentry/types";

import { isBot, isSupportedBrowser } from "@/lib/helpers/isSupportedBrowser";
import { projects, rootProjects } from "@/lib/integrations/sentry/projects.ts";
import {
  solvariContextIntegration,
  solvariFilterIntegration,
} from "@/lib/integrations/sentry/service/contextIntegration";
import { ignoreConfig } from "@/lib/integrations/sentry/service/ignored";
import { getProjectRelease } from "@/lib/integrations/sentry/service/project";
import { trackImageLoading } from "@/lib/integrations/sentry/service/trackers";

/*
  You can only have one Sentry instance running on a page.
  We use micro frontends that each have their own Sentry project.
  So we need some logic to send the errors to the right project by defining them globally
 */

function makeMicroFrontEndTransport() {
  return Sentry.makeMultiplexedTransport(
    Sentry.makeFetchTransport,
    ({ getEvent }) => {
      const projectName = getEvent()?.tags?.project as string | undefined;
      if (!projectName || !(projectName in projects)) {
        return [];
      }
      return [
        {
          dsn: projects[projectName as keyof typeof projects],
          release: getProjectRelease(window.sentryProjects?.[projectName]),
        },
      ];
    },
  );
}

function initSentryRoot(rootProject: SentryRootProject) {
  if (!isSupportedBrowser() || isBot() || getEnv().config.envBE === "local") {
    return;
  }

  Sentry.init({
    dsn: rootProjects[rootProject.project],
    environment: getEnv().config.envBE,
    transport: makeMicroFrontEndTransport(),
    release: getProjectRelease({
      ...rootProject,
      release: import.meta.env.npm_package_version as string,
    }),
    sendDefaultPii: true,
    integrations: (integrations) => [
      ...integrations.filter((integration) => integration.name !== "Vue"),
      Sentry.extraErrorDataIntegration(),
      Sentry.httpClientIntegration(),
      solvariFilterIntegration(),
      solvariContextIntegration(rootProject),
    ],
    ...ignoreConfig,
  });

  window.sentry = {
    captureException: Sentry.captureException,
    captureMessage: Sentry.captureMessage,
    trackVueErrors: (app: App) => {
      Sentry.addIntegration(Sentry.vueIntegration({ app }));
    },
  };

  trackImageLoading();
}

export { initSentryRoot };
