/* eslint-disable @typescript-eslint/naming-convention */

const rootProjects = {
  "admin-fe":
    "https://0a9c40ac3b8941eabad7b120d2521dfa@o1412996.ingest.us.sentry.io/4503902699913216",
  "my-solvari":
    "https://b7f24bfb52c1495984024540b273fe6a@o1412996.ingest.us.sentry.io/4503902696374272",
  "satellites-fe":
    "https://4359ad5a10594ecea8f5d5588ad93bf4@o1412996.ingest.us.sentry.io/4503919395799040",
  "expert-sites-fe":
    "https://a7c497b1b40549048f2d61c246eb6ea3@o1412996.ingest.sentry.io/4504253747429376",
  "main-site-fe":
    "https://57f85d2b5dbb46b7adbb35166ad914ba@o1412996.ingest.us.sentry.io/4503902687789056",
  "customer-fe":
    "https://0adca54a8fc64cca9ce0f9b9310b3716@o1412996.ingest.us.sentry.io/4503902693883904",
} as const;

const projects = {
  "mkt-ab-testing":
    "https://daf496c1b5b158862d5fed761906c494@o1412996.ingest.sentry.io/4506196233027584",
  "argus-fe":
    "https://008983dfa3b1c397803a7469032e30d2@o1412996.ingest.us.sentry.io/4507572371390464",

  "company-registration-form":
    "https://3e1be795af9844c885443a96851f3d45@o1412996.ingest.sentry.io/4504967123173376",
  "contact-form":
    "https://90abf0d73a4e4df5868c1e472e70cd49@o1412996.ingest.sentry.io/4504604884729856",
  "lead-forms":
    "https://e1692519fc434959bab97e0246c74e37@o1412996.ingest.sentry.io/4503902653775872",
} as const;

export { projects, rootProjects };
