import type { Event as SentryEvent, StackFrame } from "@sentry/types";

import type { SentryProject } from "@/lib/integrations/sentry/types";

function getEventProject(event: SentryEvent) {
  if (!window.sentryProjects) {
    return;
  }

  // Stolen from Sentry's allowedUrl source code
  // @ts-expect-error The regular stacktrace does not have enough info
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const frames = event.exception?.values?.[0]?.raw_stacktrace?.frames as
    | StackFrame[]
    | undefined;

  const framesToSearch = frames?.slice(-3).reverse();

  if (!framesToSearch) {
    return;
  }

  return Object.values(window.sentryProjects).find(({ path }) => {
    return (
      path && framesToSearch.some(({ filename }) => filename?.includes(path))
    );
  });
}

function getProjectRelease(project?: { project: string; release?: string }) {
  return project?.release ? `${project.project}@${project.release}` : "unknown";
}

function setEventProjectTag(event: SentryEvent, project?: SentryProject) {
  if (!project) {
    return event;
  }
  event.tags = { ...event.tags, project: project.project };
  return event;
}

export { getEventProject, getProjectRelease, setEventProjectTag };
